.listInside {
  list-style: inside circle;
  margin-bottom: 1.3em;
}

.blockquote {
  border-left: 0.3rem solid #eee;
  margin: 1em 0;
  padding: .5em 1em;
  font-style: italic;

  &:before {
    color: #ccc;
    font-size: 4em;
    line-height: .1em;
    margin-right: .25em;
    vertical-align: -.4em;
  }

  cite,
  footer {
    font-size: 85%;

    &:before {
      content: '\2014 \00A0';
    }
  }
}

.code {
  font-family: monospace;
  font-size: 1rem;
}

.embed {
  border: none
}

.img {
  max-width: 100%;
  height: auto;
}

.imgStretched {
  object-fit: cover;
  width: 100%;
  height: 20rem;
}

.imgBordered {
  border: 1px solid #ccc;
}

.imgBackgrounded {
  padding: 0.5rem;
}

.table {
  table-layout: auto;
}
